import React, { useEffect, useState } from "react";
import { Button, Collapse, Dropdown, Menu, Modal, Space, Spin } from "antd";
import { useApiContext } from "../../providers/ApiProvider";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import TaskRow from "./TaskRow";
import AddRow from "./AddRow";
import { stringDateMY } from "../../Helpers";
import ModalSeoContract from "../Modal/ModalSeoContract";
import TagDropDownLight from "../TagDropDownLight";
import TaskDrawer from "../Drawer/TaskDrawer";
import moment from "moment";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import ModalMainTask from "./ModalMainTask";

const { confirm } = Modal;
const { Panel } = Collapse;

export default function EtbTask({
  reload = () => {
  }
}) {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiPostEntity, apiUpdateEntity, apiDeleteEntity } = apiDispatch;
  const params = useParams();

  const [needUpdate, setNeedUpdate] = useState(0);
  const [etbNeedUpdate, setEtbNeedUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [etb, setEtb] = useState({});

  const [visible, setVisible] = useState(false);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [contentDrawer, setContentDrawer] = useState({});

  const [item, setItem] = useState({});

  useEffect(() => {
    fetchEtb();
  }, [etbNeedUpdate])

  function fetchEtb() {
    apiFetchSubResource("establishments", { id: params.id }, "get_date_etb", response => {
      setEtb(response);
    })
  }

  useEffect(() => {
    fetch();
  }, [needUpdate])

  function fetch() {
    setLoading(true)
    const data = {
      id: params.id,
      filters: [{ name: "exists[parent]", value: false }, { name: "order[plannedAt]", value: 'desc' }]
    }

    apiFetchSubResource("establishments", data, "tasks", response => {
      setTasks(response["hydra:member"]);
      setActiveKey(response["hydra:member"].map(task => task.id))
      setLoading(false)
    })
  }

  function startSeo(contract, planned, oldTask) {
    setLoadingCreate(true);

    if (contract === "takeOver")
      return takeOver(contract, planned, oldTask);

    addOnLinePublicationTask(contract, planned);
  }

  function takeOver(contract, planned, oldTask) {
    const data = {
      etbId: etb.id,
      date: moment(planned).format('YYYY-MM-DD'),
      oldTask: oldTask
    }

    apiPostEntity("planned-existing", data, response => {
      setNeedUpdate(needUpdate + 1);
      setLoadingCreate(false);

      const params = {
        seoContract: "SEO annuel",
        begeningAt: moment(planned).format('YYYY-MM-DD')
      };
      updateEtb(params);
    })
  }

  function addOnLinePublicationTask(contract, planned) {
    const data = {
      description: 'Mise en ligne',
      establishment: '/api/establishments/' + params.id,
      user: ['/api/users/' + auth.id],
      ref: "onlinePublication",
      status: "/api/tags/3",
      plannedAt: moment(planned).format('YYYY-MM-DD')
    }

    apiPostEntity("tasks", data, () => {
      setNeedUpdate(needUpdate + 1);
      setLoadingCreate(false);

      const params = {
        seoContract: contract,
        begeningAt: moment(planned).format('YYYY-MM-DD'),
        date: moment(planned).format('YYYY-MM-DD')
      };
      updateEtb(params);
    })
  }

  function updateEtb(params) {
    apiUpdateEntity("establishments", etb.id, params, () => {
      reload();
      setEtbNeedUpdate(etbNeedUpdate + 1);
    })
  }

  function refresh() {
    setNeedUpdate(needUpdate + 1);
    setEtbNeedUpdate(etbNeedUpdate + 1);
    reload();
  }

  function makeTag(status) {
    return (
      <div
        className="cursor-p flex justify-center align-center"
        style={{
          color: "#fff",
          backgroundColor: status.color,
          padding: "5px 15px",
          position: "absolute",
          top: 0,
          right: 0,
          height: '100%',
          minWidth: '100px',
        }}
        onClick={e => e.stopPropagation()}
      >
        <div>{status.name}</div>
      </div>
    )
  }

  function genExtra(task) {
    return (
      <TagDropDownLight task={task} content={makeTag(task.status)} reload={refresh} />
    )
  }

  const [visibleTask, setVisibleTask] = useState(false)

  function switchMenu(e, task) {
    e.domEvent.stopPropagation()
    switch (e.key) {
      case 'edit':
        setItem(task)
        setVisibleTask(true)
        break;
      case 'delete':
        showDeleteConfirmTask(task.id)
        break;
    }
  }

  function genHeader(task) {
    return (
      <Space>
        <span>
          {task.description}
        </span>

        {task.plannedAt &&
          <span>(plannifié pour {stringDateMY(task.plannedAt)})</span>}

        <Dropdown
          overlay={(
            <Menu onClick={e => switchMenu(e, task)}>
              <Menu.Item key="edit">Editer</Menu.Item>
              <Menu.Item key="delete">Supprimer</Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <MoreOutlined onClick={e => e.stopPropagation()} />
        </Dropdown>
      </Space>
    )
  }

  function resetTask() {
    apiPostEntity("reset-etb-tasks", { etbId: params.id }, () => {
      apiUpdateEntity('establishments', etb.id, { seoContract: null, begeningAt: null }, reponse => {
        setNeedUpdate(needUpdate + 1);
      })
    })
  }

  function editTask(id, key, value) {
    const index = tasks.findIndex(task => task.childs.some(child => child.id === id));
    const indexChild = tasks[index].childs.findIndex(child => child.id === id);
    tasks[index].childs[indexChild][key] = value;

    setTasks([...tasks]);
  }

  function showDrawer(content) {
    setContentDrawer(content);
    setVisibleDrawer(true);
  }

  function switchContract() {
    setLoading(true)
    const data = {
      etbId: etb.id,
      date: moment(tasks.find(task => task.description === 'Mise en ligne')?.plannedAt).format('YYYY-MM')
    }

    apiUpdateEntity('establishments', etb.id, { seoContract: 'SEO annuel' }, response => {
      if (tasks.some(task => task.description === 'Mise en ligne' && task.status.name === 'FULL OK')) {
        apiPostEntity("planned-existing", data, response => {
          reload();
          setEtbNeedUpdate(etbNeedUpdate + 1)
          setNeedUpdate(needUpdate + 1)
        })
      } else {
        reload();
        setEtbNeedUpdate(etbNeedUpdate + 1)
        setNeedUpdate(needUpdate + 1)
      }
    })
  }

  const seeSwitchContract = !!(etb.seoContract === 'Mise en place SEO' && tasks.some(task => task.description === 'Mise en ligne'));

  function showDeleteConfirm() {
    confirm({
      title: 'Etes-vous sure de vouloir reset les taches ?',
      icon: <ExclamationCircleOutlined />,
      content: 'Vous allez perdre toutes les taches liées a l\'etablissement',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        resetTask();
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  function deleteTask(id) {
    apiDeleteEntity('tasks', id, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }

  function showDeleteConfirmTask(id) {
    confirm({
      title: 'Etes-vous sure de vouloir supprimer cette tache ?',
      icon: <ExclamationCircleOutlined />,
      content: 'Vous allez perdre toutes les données liées a cette tache',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        deleteTask(id);
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  function closeMoadlTask() {
    setNeedUpdate(needUpdate + 1)
    setItem({})
  }

  return (
    <Spin spinning={loading}>
      <Space className="mb-10">
        {!tasks.length && !loading ?
          <Button loading={loadingCreate} onClick={() => setVisible(true)} type="primary">
            Débuter SEO
          </Button> : null}

        {seeSwitchContract &&
          <Button onClick={switchContract} type="primary">Passer en SEO
            annuel</Button>}


        {auth.roles.includes("ROLE_ADMIN") &&
          <Button type="danger" onClick={showDeleteConfirm}>Reset task</Button>}

        {!!(tasks.length && auth.roles.includes("ROLE_ADMIN")) &&
          <ModalMainTask
            reload={closeMoadlTask} item={item}
            visible={visibleTask} setVisible={setVisibleTask}
          />}
      </Space>

      {tasks.length ?
        <Collapse activeKey={activeKey} onChange={setActiveKey}>
          {tasks.map(task => (
            <Panel header={genHeader(task)} key={task.id} extra={genExtra(task)}>

              <div className="table">
                {task.childs.map(child => (
                  <TaskRow
                    key={child.id}
                    task={child}
                    reload={() => setNeedUpdate(needUpdate + 1)}
                    editTask={editTask}
                    showDrawer={showDrawer}
                    mainTask={tasks}
                    parentId={task.id}
                  />
                ))}

                <AddRow task={task} reload={() => setNeedUpdate(needUpdate + 1)} update={needUpdate} />
              </div>

            </Panel>
          ))}
        </Collapse> : null}

      <TaskDrawer
        visible={visibleDrawer}
        setVisible={setVisibleDrawer}
        content={contentDrawer}
        setContent={setContentDrawer}
        reload={() => setNeedUpdate(needUpdate + 1)}
      />
      <ModalSeoContract
        visible={visible}
        setVisible={setVisible}
        okFunction={startSeo}
      />
    </Spin>
  )
}
