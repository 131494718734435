import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { useAuthContext } from './AuthProvider.js';
import axios from "axios";

const api_endpoint = process.env.API_ENTRYPOINT + '/api/';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
const defaultState = {};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const DispatchContext = React.createContext();

const ApiProvider = ({ children }) => {
  const [dispatch] = useImmer({ ...defaultState });
  // alternatively without Immer:  const [state, dispatch] = useState({});

  return (
    <DispatchContext.Provider value={dispatch}>
      {children}
    </DispatchContext.Provider>
  );
};

function useDispatchContext() {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const authToken = auth ? auth.token : 'none';

  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error("Ut oh, where is my dispatch?");
  }

  function responseAuthControl(api_response) {
    if (
      (api_response.error && api_response.error === "AuthenticationFailure") ||
      (api_response.message && api_response.message === "Username could not be found.")
    ) {
      authLogout();
      return false;
    }
    return true;
  }

  async function apiFetchEntity(endpoint, id, external_dispatcher) {
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id + '.jsonld',
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiUpdateEntity(endpoint, id, data, external_dispatcher) {
    let ext = '.jsonld'
    if (data.withoutExt) {
      ext = '';
      delete data.withoutExt;
    }

    try {
      let response = await axios({
        method: 'patch',
        data: data,
        url: api_endpoint + endpoint + '/' + id + ext,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiFetchCollection(endpoint, params, external_dispatcher) {
    let itemsPerPage = params.itemsPerPage || 10;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      //responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function publicFetch(endpoint, params) {
    let itemsPerPage = params.itemsPerPage || 10;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
        },
      });
      return response.data
    } catch (e) {
      //responseAuthControl(e.response.data);
      return e.response.data
    }
  }

  async function apiFetchSubResource(endpoint, params, resource, external_dispatcher) {
    let id = params.id;
    let itemsPerPage = params.itemsPerPage || 30;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id + '/' + resource + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': params.json ? 'application/json' : 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },

      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiPostEntity(endpoint, data, external_dispatcher) {
    try {
      let response = await axios({
        method: 'post',
        data: data,
        url: api_endpoint + endpoint,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiPostSerp(data) {
    const username = "ludovic.mmcreation@gmail.com";
    const password = "72b87b008758fe7c";
    try {
      let response = await axios({
        method: 'post',
        data: data,
        url: "https://api.dataforseo.com/v3/serp/google/organic/task_post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'basic ' + btoa(username + ':' + password)
        },
      });
      return response.data
    } catch (e) {
      return e;
    }
  }

  async function apiDeleteEntity(endpoint, id, external_dispatcher) {
    try {
      let response = await axios({
        method: 'delete',
        url: api_endpoint + endpoint + '/' + id,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiGooglePlaceAutocomplete(q) {
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + 'googlePlaceAutocomplete?q=' + q,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      return;
    }
  }

  async function apiGooglePlaceDetail(id) {
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + 'googlePlaceDetail?place_id=' + id,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      return;
    }
  }

  async function getPlacesFromWallet(name) {
    try {
      let response = await axios({
        method: 'GET',
        url: "https://api.wallet.mmcreation.com/api/mmc_places?name=" + name,
        headers: {
          'Accept': '*/*',
        },
      });
      return response.data
    } catch (e) {
      return;
    }
  }

  // async function redirections404(hid, data = null) {
  //   if (data === null) {
  //     try {
  //       let response = await axios({
  //         method: 'GET',
  //         url: `${process.env.HAPI_CMS_ENDPOINT}/api/seo/404-rw?hid=${hid}`,
  //         headers: {
  //           'Accept': '*/*',
  //         }
  //       });
  //       return response.data
  //     } catch (e) {
  //       console.error('Erreur redirections404:', e);
  //       return null;
  //     }
  //   } else {
  //     try {
  //       let response = await axios({
  //         method: 'POST',
  //         url: `${process.env.HAPI_CMS_ENDPOINT}/api/seo/404-rw?hid=${hid}`,
  //         data: { data },
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //         }
  //       });
  //       return response.data
  //     } catch (e) {
  //       console.error('Erreur redirections404:', e);
  //       return null;
  //     }
  //   }
  // }

  return {
    apiFetchEntity,
    apiUpdateEntity,
    apiFetchCollection,
    apiFetchSubResource,
    apiPostEntity,
    apiDeleteEntity,
    apiGooglePlaceAutocomplete,
    apiGooglePlaceDetail,
    getPlacesFromWallet,
    apiPostSerp,
    publicFetch,
    // redirections404
  };
};

const useApiContext = () => {
  return [useDispatchContext()]
}

// ---------------------------------------------------
// final export (addapt useContext and Provider name)
// ---------------------------------------------------
export { useApiContext, ApiProvider, DispatchContext };
