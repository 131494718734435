import React, { useState } from 'react';
import { Modal, Radio, DatePicker, Space } from 'antd';
import moment from 'moment';

export default function ModalSeoContract({ visible, setVisible, okFunction }) {

  const [contract, setContract] = useState("SEO annuel");
  const monthFormat = 'YYYY/MM';
  const [planned, setPlanned] = useState(moment(nowMonth(), monthFormat));
  const [generateOldTask, setGenerateOldTask] = useState(true);

  function nowMonth() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;

    return [year, month].join('/');
  }

  const handleOk = () => {
    okFunction(contract, planned, generateOldTask)
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function handleChange(date, string) {
    if (string)
      setPlanned(moment(string, monthFormat))
    else
      setPlanned(moment(nowMonth(), monthFormat))
  }

  function wording() {
    switch (contract) {
      case "SEO annuel":
        return "Date anniversaire";
      case "Mise en place SEO":
        return "Date prévu pour la mise en ligne";
      case "takeOver":
        return "Date de la mise en ligne";
    }
  }

  return (
    <Modal title="Type de contrat" visible={visible} onOk={handleOk} onCancel={handleCancel}>
      <Space direction="vertical" size="large">

        <div>
          <Radio.Group onChange={e => setContract(e.target.value)} value={contract}>
            <Radio value="SEO annuel">
              SEO annuel
            </Radio>
            <Radio value="Mise en place SEO">
              Mise en place SEO
            </Radio>
            <Radio value="takeOver">
              Reprise contrat en cours
            </Radio>
          </Radio.Group>
        </div>


        {contract === "takeOver" &&
          <Space direction="vertical">
            <div>Génerer tout l'historique :</div>
            <div>
              <Radio.Group onChange={e => setGenerateOldTask(e.target.value)} value={generateOldTask}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non</Radio>
              </Radio.Group>
            </div>
          </Space>}

        <Space direction="vertical">

          <div>
            {wording()} :
          </div>
          <div>
            <DatePicker value={planned} onChange={handleChange} picker="month" />
          </div>
        </Space>

      </Space>
    </Modal>
  )
}
